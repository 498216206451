<template>
  <div class="flex-grow-1 d-flex align-items-center justify-content-center">
    <div class="text-center">
      <img class="mb-3" width="150" height="150" :src="require('@/assets/images/emoji_1_clean.png')" alt="">

      <p class="h3 mb-3 text-center">
        Oops... something went wrong!
      </p>

      <b-btn to="/payments" class="w-100 rounded-pill button-gradient-primary border-0" >
        Try again
      </b-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "failed_payment"
}
</script>

<style scoped>

</style>